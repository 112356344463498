<template>
    <div class="h-screen">
      <div class="text-green-1 relative">
        <div class="flex flex-col">
          <Nav class="z-50" />
          <BrandNavigator class="z-50" />
          <router-view class="z-40" />
          <Footer class="z-40" />
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import Nav from '../navigation/LavishHeader.vue';
  import BrandNavigator from '../BrandNavigator.vue';
  import Footer from '../footer/LavishFooter.vue';
  export default {
    components: {
      Nav,
      Footer,
      BrandNavigator
    },
  }
  </script>
  